import { Theme, alpha } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid-premium';
import { scrollbarWidth } from 'utils/constants';

const ODD_OPACITY = 0.1;

/* To be used with this param
```
  getRowClassName={(params) =>
    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
  }
```
*/
export const STRIPPED_GRID = (theme: Theme) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[50],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
});

export const NONE_OUTLINED_SELECTED_CELL = {
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
    outline: 'none !important',
  },
};

export const HIDE_NUMBER_ON_DRAG_AND_DROP = {
  '& .MuiDataGrid-rowReorderCellPlaceholder': {
    visibility: 'hidden',
  },
};

export const INCONSISTENT_REFERENCE = {
  '& .inconsistent-reference': {
    bgcolor: 'cautionRed.50',
    '&:hover, &.Mui-hovered': {
      bgcolor: 'cautionRed.100',
    },
  },
};

export const DATA_GRID_CUSTOM_SCROLLBAR = {
  '& .MuiDataGrid-virtualScroller': {
    cursor: 'auto',
    overflow: 'scroll',
  },
  '& ::-webkit-scrollbar': {
    width: `${scrollbarWidth}px`,
    height: '5px',
    appearance: 'none',
    cursor: 'pointer !important',
  },
  '& ::-webkit-scrollbar-track': {
    backgroundColor: 'grey.50',
    cursor: 'pointer',
  },
  '& ::-webkit-scrollbar-thumb': {
    backgroundColor: 'grey.200',
    borderRadius: '5px',
    cursor: 'pointer !important',
  },
};

export const DISABLE_SELECTED_HIGHLIGHT = {
  '& .Mui-selected': {
    backgroundColor: 'transparent !important',
  },
};

export const MAIN_COLUMN = {
  '& .main-column': {
    borderRight: '2px solid',
    borderRightColor: 'grey.400',
  },
};

export const VERTICAL_LINES = {
  [`& .${gridClasses.cell}`]: {
    borderRight: `1px solid`,
    borderRightColor: 'grey.100',
  },
};

export const FIRST_COLUMN_WITHOUT_VERTICAL_LINES = {
  [`& .${gridClasses.cell}:first-of-type`]: {
    borderRight: '0 !important',
  },
};

export const HIDE_HEADERS = {
  '--DataGrid-headersTotalHeight': '0px !important',
  '& .MuiDataGrid-columnHeaders': { display: 'none' },
  '& .MuiDataGrid-virtualScroller': { marginTop: '0!important' },
};
export const SHOW_HEADERS = {
  '& .MuiDataGrid-columnHeaders': { display: 'block' },
  '& .MuiDataGrid-virtualScroller': { marginTop: '0!important' },
};

export const INLINE_EDIT_CELL = {
  '.MuiDataGrid-cell--editing.fullwidth-cell, .fullwidth-cell:has([role="button"])': {
    px: 0,
  },
  '.MuiDataGrid-cell--editing.transparent-border-cell': {
    outlineColor: 'transparent !important',
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    '.MuiInputBase-root': {
      backgroundColor: 'white',
    },
  },
};

export const ROUNDED_TABLE = {
  '& .MuiDataGrid-root': {
    borderRadius: '8px',
  },
  '& .MuiDataGrid-columnHeaders': {
    borderRadius: '8px 8px 0 0',
  },
  '& .MuiDataGrid-virtualScroller': {
    borderRadius: '0 0 8px 8px',
  },
};

export const HIDE_TABLE_FILLER = {
  '& .MuiDataGrid-filler': {
    height: '0px !important',
    display: 'none',
  },
};

export const EXTERNAL_BORDER = {
  '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-virtualScroller': {
    border: '1px solid',
    borderColor: 'grey.200',
  },
};

export const HIDE_AGGREGATION_LABEL = {
  '.MuiDataGrid-aggregationColumnHeaderLabel': {
    display: 'none',
  },
};

export const ERROR_ROW = {
  '& .row-error': {
    backgroundColor: (theme: Theme) => `${theme.palette.cautionRed[50]} !important`,
  },
};

const DEFAULT_HEADER_HEIGHT = 125;
export const getCustomHeightSx = (
  densityType: string,
  extraHeight: number,
  totalRows: number | null | undefined,
  headerHeight?: number,
) => {
  const finalRowNumber = (totalRows ?? 0) > 0 ? (totalRows ?? 0) : 2;
  const HEADER_HEIGHT = headerHeight ?? DEFAULT_HEADER_HEIGHT;
  const contentHeight = `${(densityType === 'compact' ? 24 : 36) * finalRowNumber + extraHeight + (densityType === 'compact' ? 8 : 0)}px !important`;

  return {
    '& .MuiDataGrid-pinnedColumns': {
      minHeight: `unset !important`,
    },
    '& .MuiDataGrid-virtualScrollerContent': {
      height: contentHeight,
      minHeight: contentHeight,
      maxHeight: contentHeight,
    },
    '& .MuiDataGrid-row': {
      height: densityType === 'compact' ? '24px !important' : 'unset',
      minHeight: densityType === 'compact' ? '24px !important' : 'unset',
      cursor: 'pointer',
    },
    '& .MuiDataGrid-cell': {
      height: densityType === 'compact' ? '24px !important' : 'unset',
      minHeight: densityType === 'compact' ? '24px !important' : 'unset',
      maxHeight: densityType === 'compact' ? '24px !important' : '36px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiDataGrid-columnHeaders': {
      '--DataGrid-containerBackground': 'white !important',
      height: `${HEADER_HEIGHT}px !important`,
      maxHeight: 'unset !important',
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: 'white !important',
      height: `${HEADER_HEIGHT}px !important`,
      maxHeight: 'unset !important',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      backgroundColor: 'white !important',
      height: `${HEADER_HEIGHT}px !important`,
    },
    '& .MuiDataGrid-columnHeaderRow': {
      backgroundColor: 'white !important',
      height: `${HEADER_HEIGHT}px !important`,
    },
  };
};
