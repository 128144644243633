import { Button, ButtonProps } from '@mui/material';
import { joinSx } from 'utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import usePermissions from 'features/Auth/hook/usePermissions';

export function CreateProjectDashboardButton({
  sx,
  onClick,
}: Pick<ButtonProps, 'sx' | 'onClick'>) {
  const { hasPerm, Permissions } = usePermissions();

  if (!hasPerm(Permissions.ADD_PROJECT_DASHBOARD)) return null;
  return (
    <Button
      data-testid="create_dashboard_button"
      sx={joinSx({ px: 2 }, sx)}
      variant="blueGhost"
      startIcon={<FontAwesomeIcon icon={faPlus} />}
      onClick={onClick}
    >
      Create dashboard
    </Button>
  );
}
