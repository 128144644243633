import Grid from '@mui/material/Grid';
import { DialogContent, Divider } from '@mui/material';
import { FlyoutDialog } from 'components/FlyoutDialog';
import { faUserShield } from '@fortawesome/pro-regular-svg-icons';
import { FlyoutDialogProps } from 'components/FlyoutDialog/FlyoutDialog';
import { RoleImpersonationTable } from './RoleImpersonationTable';

type RoleImpersonationFlyoutProps = Omit<FlyoutDialogProps, 'children'>;

export const RoleImpersonationFlyout = (props: RoleImpersonationFlyoutProps) => (
  <FlyoutDialog titleIcon={faUserShield} title="Role Impersonation" {...props}>
    <DialogContent data-testid="role-impersonation-dialog">
      <Grid container direction="column">
        <Divider sx={{ mt: 4, mb: 2 }} />
        <RoleImpersonationTable />
      </Grid>
    </DialogContent>
  </FlyoutDialog>
);
