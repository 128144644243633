import {
  DashboardCustomMetric,
  DashboardMetric,
  DEFAULT_PROJECT_DASHBOARD_PORTFOLIO,
  ProjectDashboard,
  ProjectDashboardMetricSetting,
  ProjectDashboardVisibility,
  UserDashboardMembership,
} from 'types/Dashboard';
import { PrivateURL } from 'Urls';
import * as Yup from 'yup';
import { UserBrief } from 'types/User';
import { Resources } from 'api/Resources';
import { Nullable } from 'types/Common';

export function getProjectDashboardUrl(projectDashboard: ProjectDashboard) {
  return projectDashboard.id === DEFAULT_PROJECT_DASHBOARD_PORTFOLIO
    ? PrivateURL.PROJECT_DASHBOARD_PORTFOLIO
    : PrivateURL.PROJECT_DASHBOARD_DETAIL.replace(':id', String(projectDashboard.id));
}

export const formItemsValuesKeys = [
  'id',
  'name',
  'description',
  'picture',
  'type',
  'related_projects',
  'projects',
  'project_groups',
] as (keyof ProjectDashboard)[];

export const formHelperValuesKeys = [
  'project_group_ids',
  'project_ids',
  'pictureFile',
] as const;

export const projectDashboardSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  type: Yup.string().required('Type is required'),
});

export const formValuesKeys = [...formItemsValuesKeys, ...formHelperValuesKeys];

type FormValuesKeys = (typeof formValuesKeys)[number];

export type EditingProjectDashboard = Pick<
  Omit<ProjectDashboard, 'id' | 'users' | 'memberships'> & {
    id?: number;
    project_group_ids: number[];
    project_ids: number[];
    users: UserBrief[];
    memberships: UserDashboardMembership['membership'][];
    pictureFile: File;
  },
  FormValuesKeys
>;

export const DASHBOARD_VISIBILITIES = {
  INTERNAL: {
    label: 'Internal',
    tooltip: 'Used to share key information between internal company users',
  },
  EXTERNAL: {
    label: 'External',
    tooltip: 'Used to show clients, data that will be relevant for their needs.',
  },
} as Record<ProjectDashboardVisibility, { label: string; tooltip: string }>;

export type EditingProjectDashboardMetricSetting = Pick<
  ProjectDashboardMetricSetting,
  'name'
> &
  Nullable<Pick<ProjectDashboardMetricSetting, 'id'>> & {
    setting_order?: number;
    metric: ProjectDashboardMetricSetting['metric'] | null;
    payload?: ProjectDashboardMetricSetting['payload'] | null;
  };

export type EditingProjectDashboardCustomMetricSetting = Pick<
  ProjectDashboardMetricSetting,
  'name'
> &
  Nullable<Pick<ProjectDashboardMetricSetting, 'id'>> & {
    setting_order?: number;
    metric: Omit<
      DashboardCustomMetric,
      | 'id'
      | 'chart_type'
      | 'main_axis'
      | 'secondary_axis'
      | 'group_axis'
      | 'show_categories'
    > &
      Nullable<
        Pick<
          DashboardCustomMetric,
          | 'id'
          | 'chart_type'
          | 'main_axis'
          | 'secondary_axis'
          | 'group_axis'
          | 'show_categories'
        >
      >;
  };

export function getAvailableMetrics({
  projectDashboard,
  metricSettings,
  allMetrics,
}: {
  projectDashboard: ProjectDashboard;
  metricSettings: ProjectDashboardMetricSetting[];
  allMetrics: DashboardMetric[];
}) {
  return allMetrics.filter((metric) => {
    if (metric.metric_key === 'custom') return false;
    if (projectDashboard.type === 'EXTERNAL') {
      if (metric.type === 'INTERNAL') return false;
    }

    return metricSettings
    // return !metricSettings.find((s) => s.metric.id === metric.id);
  });
}

export const isPortfolioDashboard = (projectDashboardId: number) => {
  return projectDashboardId === DEFAULT_PROJECT_DASHBOARD_PORTFOLIO;
};
export const WIDGETS_MIN_HEIGHT = '23.5rem';

export const getProjectDashboardMetricUrl = ({
  projectDashboardId,
  metricSettingId,
  metricSlug,
}: {
  projectDashboardId: ProjectDashboard['id'];
  metricSettingId: ProjectDashboardMetricSetting['id'] | undefined;
  metricSlug: string;
}) => {
  return isPortfolioDashboard(projectDashboardId)
    ? Resources.DASHBOARD_PORTFOLIO_METRIC.replace('<str:metric_slug>', metricSlug)
    : metricSettingId
      ? Resources.DASHBOARD_PROJECT_DASHBOARD_SETTING_DATA.replace(
          '<int:dashboard_pk>',
          projectDashboardId.toString(),
        ).replace('<int:pk>', metricSettingId.toString())
      : null;
};

export type EditingProjectDashboardCustomMetricSettingAi = Omit<
  EditingProjectDashboardCustomMetricSetting,
  'metric'
> &
  Pick<ProjectDashboardMetricSetting, 'ai_assistant_id'> & {
    metric:
      | (Omit<EditingProjectDashboardCustomMetricSetting['metric'], 'feature'> &
          Nullable<Pick<EditingProjectDashboardCustomMetricSetting['metric'], 'feature'>>)
      | null;
  };
