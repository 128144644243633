import newLogo from 'assets/img/concntric-logo-light.svg';
import { Box } from '@mui/system';
import { useCurrentUserCompanyLogo } from 'features/Company/hooks/company';

export const CompanyLogo = () => {
  const {
    companyLogoQuery: { data: picture },
  } = useCurrentUserCompanyLogo();

  return (
    <Box
      component="img"
      src={picture ? picture.data : newLogo}
      sx={{
        height: '3rem',
        maxWidth: '10rem',
        mr: 2,
      }}
    />
  );
};
