import { useEffect, useRef } from 'react';
import { atom, useAtom } from 'jotai/index';

// TODO: This atom should be just a state
const userLocationAtom = atom<{ lat: number; lng: number } | undefined>(undefined);
// TODO: This atom should be just a state
const userLocationStatusAtom = atom<
  'idle' | 'success' | 'error' | 'loading' | 'disabled'
>('idle');

export function useUserLocation({ enabled = true }: { enabled?: boolean } = {}) {
  const [status, setStatus] = useAtom(userLocationStatusAtom);
  const [userLocation, setUserLocation] = useAtom(userLocationAtom);
  const enabledRef = useRef(enabled);

  useEffect(() => {
    if (status !== 'idle' || !enabledRef.current) {
      return;
    }
    setStatus('loading');

    function setupPosition() {
      navigator.geolocation.getCurrentPosition(
        (p) => {
          setUserLocation({
            lat: p.coords.latitude,
            lng: p.coords.longitude,
          });
          setStatus('success');
        },
        () => {
          setStatus('error');
        },
      );
    }

    navigator.permissions
      .query({ name: 'geolocation' })
      .then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          setupPosition();
        } else {
          setStatus('error');
        }
        result.addEventListener('change', () => {
          setupPosition();
        });
        return null;
      })
      .catch(() => {
        setStatus('error');
      });
  }, [userLocation, setUserLocation, setStatus, status]);

  return { userLocation, status: !enabledRef.current ? 'disabled' : status };
}
