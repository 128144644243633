import React, { BaseSyntheticEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Slide,
  Breakpoint,
  SlideProps,
  SxProps,
  Theme,
  Tooltip,
  Button,
} from '@mui/material';
import { faXmark, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FlyoutDialogTitleIcon } from 'components/FlyoutDialog/FlyoutDialogTitleIcon';
import { joinSx } from 'utils/helpers';

const DialogTransition = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

export type FlyoutDialogProps = {
  titleIcon?: IconDefinition;
  title?: React.ReactNode | string;
  titleActions?: React.ReactNode | React.ReactNode[] | null;
  onClose: () => void;
  children: React.ReactNode;
  width?: Breakpoint;
  open: boolean;
  showBorderOnCloseButton?: boolean;
  sx?: SxProps<Theme>;
};

export const FlyoutDialog = ({
  titleIcon,
  title,
  titleActions,
  onClose,
  children,
  width,
  sx,
  showBorderOnCloseButton = false,
  ...other
}: FlyoutDialogProps) => {
  const widthKey = width || 'sm';
  const titleComponent =
    typeof title === 'string' ? (
      <DialogTitle>
        <Box sx={{ display: 'flex', ml: titleIcon ? 0 : 1 }}>
          {titleIcon && <FlyoutDialogTitleIcon icon={titleIcon} />}
          {title}
        </Box>
      </DialogTitle>
    ) : (
      title
    );
  // If we implement the custom styles with styled() we end up
  // with a broken "hide" transition.
  // The dialog just goes away without any animation.
  const handleClose = (e: BaseSyntheticEvent) => {
    e?.stopPropagation?.();
    onClose();
  };
  return (
    <Dialog
      TransitionComponent={DialogTransition}
      sx={joinSx(
        {
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
          },
          '& .MuiDialog-paper': ({ breakpoints }) => ({
            width: breakpoints.values[widthKey],
            maxWidth: breakpoints.values[widthKey],
            margin: '0px',
            maxHeight: '100%',
            height: '100%',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }),
        },
        sx,
      )}
      onClose={(e) => {
        window.userGuiding?.finishPreview();
        handleClose(e as BaseSyntheticEvent);
      }}
      {...other}
    >
      <Box
        sx={{
          position: 'absolute',
          right: (theme) => theme.spacing(4),
          top: (theme) => theme.spacing(2),
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {titleActions}
        <Tooltip title="Close" arrow placement="top">
          {showBorderOnCloseButton ? (
            <Button
              onClick={handleClose}
              size="small"
              sx={{
                minWidth: 0,
                width: '2rem',
                height: '2rem',
                '& span': {
                  mr: 0,
                },
              }}
              variant="greyGhost"
              startIcon={
                <Box
                  component={FontAwesomeIcon}
                  sx={{
                    color: 'grey.500',
                  }}
                  icon={faXmark}
                  size="sm"
                />
              }
            />
          ) : (
            <IconButton onClick={handleClose} sx={{ height: '2rem', width: '2rem' }}>
              <FontAwesomeIcon icon={faXmark} />
            </IconButton>
          )}
        </Tooltip>
      </Box>
      {titleComponent}
      {children}
    </Dialog>
  );
};
