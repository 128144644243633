import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import { RiskOrDraft } from 'types/Risk';
import { SubItem } from 'types/SubItem';
import { difference, merge } from 'lodash-es';
import { ItemTableColumnField } from '../components/ItemsTable/types';
import { useAtomWithUserId } from 'hooks/useAtomWithUserId';
import { ForesiteFlyoutsState } from 'features/Foresite/types/ui';
import { DesignMilestoneOrDraft } from 'types/DesignMilestones';
import { TimelineActivity } from 'types/Timeline';
import { IssueOrDraft } from 'types/Issue';
import { ProjectDashboardDetailsId } from 'types/Dashboard';
import { useTableColumnOrder } from 'hooks/useTableColumOrder';

const originalState: ForesiteFlyoutsState = {
  isLibraryIdeasListOpen: false,
  libraryIdeaDetailsId: false,
  riskDetailsId: undefined,
  milestoneDetailsId: undefined,
  timelineActivityDetailsId: undefined,
  issueDetailsId: undefined,
  projectDashboardDetailsId: undefined,
  isCustomizeTimelineOpen: false,
  isCustomizeEstimateOpen: false,
  isCustomizePortfolioOpen: false,
  isCustomizeTVDOpen: false,
  isCustomizeProcurementOverviewOpen: false,
};

const foresiteFlyoutsAtom = atom<ForesiteFlyoutsState>(originalState);
foresiteFlyoutsAtom.debugLabel = 'foresiteFlyoutsAtom';

export const useForesiteFlyout = () => {
  const [foresiteFlyoutsState, setForesiteFlyoutsState] = useAtom(foresiteFlyoutsAtom);

  const setNewStateValue = useCallback(
    (
      flyout: keyof ForesiteFlyoutsState,
      value: boolean | number | RiskOrDraft['id'] | 'new',
    ) => {
      const newState = {
        ...originalState,
        [flyout]: value,
      };
      setForesiteFlyoutsState(newState);
    },
    [setForesiteFlyoutsState],
  );

  const setLibraryIdeaDetailsId = (id: number) => {
    setNewStateValue('libraryIdeaDetailsId', id);
  };

  const setRiskDetailsId = useCallback(
    (id: RiskOrDraft['id']) => {
      setNewStateValue('riskDetailsId', id);
    },
    [setNewStateValue],
  );

  const setMilestoneDetailsId = useCallback(
    (id: DesignMilestoneOrDraft['id']) => {
      setNewStateValue('milestoneDetailsId', id);
    },
    [setNewStateValue],
  );
  const setIssueDetailsId = useCallback(
    (id: IssueOrDraft['id']) => {
      setNewStateValue('issueDetailsId', id);
    },
    [setNewStateValue],
  );

  const setTimelineActivityDetailsId = useCallback(
    (id: TimelineActivity['id']) => {
      setNewStateValue('timelineActivityDetailsId', id);
    },
    [setNewStateValue],
  );

  const setProjectDashboardDetailsId = useCallback(
    (id: ProjectDashboardDetailsId) => {
      setNewStateValue('projectDashboardDetailsId', id);
    },
    [setNewStateValue],
  );

  const openLibraryIdeasList = () => {
    setNewStateValue('isLibraryIdeasListOpen', true);
  };

  const openCustomizeTimeline = () => {
    setNewStateValue('isCustomizeTimelineOpen', true);
  };
  const openCustomizeEstimate = () => {
    setNewStateValue('isCustomizeEstimateOpen', true);
  };
  const openCustomizePortfolio = () => {
    setNewStateValue('isCustomizePortfolioOpen', true);
  };
  const openCustomizeTVD = () => {
    setNewStateValue('isCustomizeTVDOpen', true);
  };

  const openCustomizeProcurementOverview = () => {
    setNewStateValue('isCustomizeProcurementOverviewOpen', true);
  };

  const closeFlyouts = useCallback(
    () => {
      setForesiteFlyoutsState(originalState);
    }, // eslint-disable-line},
    [setForesiteFlyoutsState],
  );

  return {
    ...foresiteFlyoutsState,
    setMilestoneDetailsId,
    setTimelineActivityDetailsId,
    setLibraryIdeaDetailsId,
    setIssueDetailsId,
    setProjectDashboardDetailsId,
    openLibraryIdeasList,
    closeFlyouts,
    setRiskDetailsId,
    openCustomizeTimeline,
    openCustomizeEstimate,
    openCustomizePortfolio,
    openCustomizeTVD,
    openCustomizeProcurementOverview,
  };
};

const FORESITE_ITEMS_TABLE_WIDTHS_LS_KEY = 'concntric-foresite-items-table-widths';

// This object keys should match DataGrid column fields
const itemsTableDefaultWidths: Record<ItemTableColumnField, number | undefined> = {
  id: 40,
  _mutuallyExclusiveGroups: 70,
  code: 60,
  name: undefined,
  _scenarios: 100,
  cost: 120,
  estimated_sq_ft: 120,
  component_name: 130,
  __rangeOfOutcomeLowerLimit: 250,
  __rangeOfOutcomeUpperLimit: 250,
  due_date: 130,
  date_added: 130,
  assignee: 90,
  updated_by: 70,
  _status: 120,
  tags: 110,
  priority: 130,
  milestone_name: 110,
} as const;

const itemsTableWidthsAtom = atomWithStorage(
  FORESITE_ITEMS_TABLE_WIDTHS_LS_KEY,
  itemsTableDefaultWidths,
  undefined,
  { getOnInit: true },
);
itemsTableWidthsAtom.debugLabel = 'ForesiteItemsTableWidths';

export const useItemsTableWidths = () => {
  const [itemsTableWidthsState, setItemsTableWidthsAtom] =
    useAtomWithUserId(itemsTableWidthsAtom);

  const itemsTableWidths = useMemo(() => {
    return merge(itemsTableDefaultWidths, itemsTableWidthsState);
  }, [itemsTableWidthsState]);

  const updateFieldWidth = (field: string, value: number) => {
    if (Object.keys(itemsTableDefaultWidths).includes(field)) {
      setItemsTableWidthsAtom({ ...itemsTableWidths, [field]: value });
    }
  };

  return {
    itemsTableWidths,
    updateFieldWidth,
  };
};

const itemsTableColumnOrderAtom = atomWithStorage<string[] | null>(
  'concntric-foresite-items-table-column-order',
  null,
  undefined,
  { getOnInit: true },
);
itemsTableColumnOrderAtom.debugLabel = 'ForesiteItemsTableColumnOrder';

export const useItemsTableColumnOrder = ({
  initialValue,
}: {
  initialValue: string[];
}) => {
  const [itemsTableColumnOrder, setItemsTableColumnOrder] = useAtomWithUserId(
    itemsTableColumnOrderAtom,
  );

  let curatedItemsTableColumnOrder: string[] = [];
  if (itemsTableColumnOrder) {
    curatedItemsTableColumnOrder = [...itemsTableColumnOrder];
    const itemsDifference = difference(initialValue, itemsTableColumnOrder);

    // we added a column, and we try to respect its original intended position
    itemsDifference.forEach((diff) => {
      const indexOfInitial = initialValue.indexOf(diff);

      const insertIntoPosition = Math.min(
        curatedItemsTableColumnOrder.length,
        indexOfInitial,
      );
      curatedItemsTableColumnOrder.splice(insertIntoPosition, 0, diff);
    });
  } else {
    curatedItemsTableColumnOrder = initialValue;
  }

  return {
    itemsTableColumnOrder: curatedItemsTableColumnOrder,
    setItemsTableColumnOrder: setItemsTableColumnOrder as (value: string[]) => void,
  };
};

const FORESITE_SUB_ITEMS_TABLE_WIDTHS_LS_KEY =
  'concntric-foresite-subitems-table-widths-v2.1';

// This object keys should match DataGrid column fields
const subItemsTableDefaultWidths: Record<
  keyof Pick<SubItem, 'scope' | 'quantity' | 'measure' | 'unit_cost' | 'extension'>,
  number | undefined
> & {
  [key in `cost_group_definition_${number}`]: number | undefined;
} & { cost_group_custom_format: number; __group_by_net: number } = {
  __group_by_net: 65,
  scope: 200,
  cost_group_custom_format: 130,
  quantity: 55,
  measure: 65,
  unit_cost: 150,
  extension: 140,
};

const subItemsTableWidthsAtom = atomWithStorage(
  FORESITE_SUB_ITEMS_TABLE_WIDTHS_LS_KEY,
  subItemsTableDefaultWidths,
  undefined,
  { getOnInit: true },
);
subItemsTableWidthsAtom.debugLabel = 'ForesiteSubItemsTableWidths';

export const useSubItemsTableWidths = () => {
  const [subItemsTableWidthsState, setSubItemsTableWidthsAtom] = useAtomWithUserId(
    subItemsTableWidthsAtom,
  );

  const subItemsTableWidths = useMemo(() => {
    return merge(
      subItemsTableDefaultWidths,
      subItemsTableWidthsState,
    ) as typeof subItemsTableDefaultWidths;
  }, [subItemsTableWidthsState]);

  const updateFieldWidth = (field: string, value: number) => {
    if (Object.keys(subItemsTableDefaultWidths).includes(field)) {
      setSubItemsTableWidthsAtom({ ...subItemsTableWidths, [field]: value });
    } else if (field.includes('cost_group_definition_')) {
      setSubItemsTableWidthsAtom({ ...subItemsTableWidths, [field]: value });
    }
  };

  return {
    subItemsTableWidths,
    updateFieldWidth,
  };
};

const subItemsTableColumnOrderAtom = atomWithStorage<string[] | null>(
  'sub-items-table-column-order',
  null,
  undefined,
  { getOnInit: true },
);
subItemsTableColumnOrderAtom.debugLabel = 'SubItemsTableColumnOrder';

export const useSubItemsTableColumnOrder = ({
  initialValue,
}: {
  initialValue: string[];
}) => {
  return useTableColumnOrder(initialValue, subItemsTableColumnOrderAtom);
};
