import { GridColDef, GridColumnOrderChangeParams } from '@mui/x-data-grid-premium';
import { changeColumnOrder } from 'components/DataTable/hooks/useTableHelpers';
import { atomWithStorage } from 'jotai/utils';
import { useAtomWithUserId } from 'hooks/useAtomWithUserId';

export const useTableColumnOrder = (
  initialValue: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  atom: ReturnType<typeof atomWithStorage<any>>,
) => {
  const [tableColumnOrder, setTableColumnOrder] = useAtomWithUserId(atom);

  const handleColumnOrderChange = (columnChange: GridColumnOrderChangeParams) => {
    const updated = changeColumnOrder(tableColumnOrder || initialValue, columnChange);
    setTableColumnOrder(updated);
  };

  const sortColumns = (columns: GridColDef[]) => {
    const fieldIndexHashMap: Record<string, number> | null = tableColumnOrder
      ? Object.fromEntries(
          tableColumnOrder.map((columnField: string, index: number) => [
            columnField,
            index,
          ]),
        )
      : null;

    return fieldIndexHashMap
      ? columns.toSorted(
          (a: GridColDef, d: GridColDef) =>
            fieldIndexHashMap[a.field] - fieldIndexHashMap[d.field],
        )
      : columns;
  };

  return {
    handleColumnOrderChange,
    sortColumns,
  };
};
